@font-face {
  font-family: alt;
  src: url(./ALT_REKT_SOLID.ttf);
}
.App {
  text-align: center;
}
.mintContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.gif {
  width: 25vw;
  height: auto;
  margin-bottom: 6vh;
  border-radius: 20px;
  box-shadow: rgba(0, 14, 143, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 15vw;
  height: auto;
  margin-bottom: 0vh;
}
.MuiButton-label {
  font-size: 2.2vh !important;
  /* width: 6.7vw !important; */
  font-family: alt;

  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}
.mint__quantity {
  font-size: 1.3vw;
  margin-bottom: 2.5vh;
  font-family: alt;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 800px) {
  .gif {
    width: 60vw;
    height: auto;
  }
  .logo__image {
    width: 30vw;
    height: auto;
  }
  .mint__quantity {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
}

html {
  background: url(bg.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.MuiButton-containedPrimary {
  color: #fff;
  font-family: alt;
  background-color: #bf4cd4 !important;
}
.makeStyles-root-1 .MuiDialogTitle-root,
.MuiButton-contained {
  background-color: #bf4cd4 !important;
  font-family: alt;
  color: white !important;
}
.jss1 .MuiDialogTitle-root {
  background-color: #bf4cd4 !important;
}
